import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Navigate, Route, Routes} from 'react-router-dom'
import {RecherchePage} from "./RecherchePage";
import {ClientAdresseEdit} from "../shop/clients/pages/client/ClientAdresseEdit";
import {RecherchePiecePage} from "./RecherchePiecePage";

const VehiculesWrapper = () => {
  const intl = useIntl()
  return (
      <>
          <PageTitle breadcrumbs={[]}>Véhicules</PageTitle>
          <Routes>

              <Route
                  path='recherche'
                  element={
                      <>
                          <RecherchePage />
                      </>
                  }
              />
              <Route
                  path='pieces/:reference'
                  element={
                      <>
                          <RecherchePiecePage />
                      </>
                  }
              />
              <Route
                  path='pieces'
                  element={
                      <>
                          <RecherchePiecePage />
                      </>
                  }
              />
              <Route index element={<Navigate to='/vehicules/recherche' />} />
          </Routes>
      </>
  )
}

export {VehiculesWrapper}
