import React, {useState} from "react";
import {ModeleSelectInput} from "./Models";

const Autocomplete = (props) => {
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [filteredSuggestions, seFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [userInput, setUserInput] = useState('');

    const onChange = (e) => {
        const { suggestions } = props;
        const userInput = e.currentTarget.value;

        const filteredSuggestions = suggestions.filter(
            suggestion =>
                suggestion.label.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        setActiveSuggestion(0);
        seFilteredSuggestions(filteredSuggestions);
        setShowSuggestions(true);
        setUserInput(e.currentTarget.value);
    }

    const onKeyDown = (e) => {
        if ([13,38,40,27].includes(e.keyCode)) {
            e.preventDefault();
        }
        if (e.keyCode === 27) {
            setActiveSuggestion(0);
            setShowSuggestions(false);
        } else if (e.keyCode === 13) {
            if (filteredSuggestions.length && activeSuggestion < filteredSuggestions.length) {
                props.onSelect(filteredSuggestions[activeSuggestion]);
            } else {
                props.onSelect({
                    value: -1,
                    label: userInput
                });
            }
            setActiveSuggestion(0);
            setShowSuggestions(false);
            setUserInput("");
        } else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }
            setActiveSuggestion(activeSuggestion - 1);
        }
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            setActiveSuggestion(activeSuggestion + 1);
        }
    }

    const onClick = (index) => {
        props.onSelect(filteredSuggestions[index]);
        setActiveSuggestion(0);
        seFilteredSuggestions([]);
        setShowSuggestions(false);
        setUserInput("");
    }

    const renderSuggestionsList = () => {
        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                return (
                    <ul className={"suggestions"}>
                        {filteredSuggestions.map((suggestion: ModeleSelectInput, index) => {
                            let className;

                            if (index === activeSuggestion) {
                                className = "suggestion-active";
                            }
                            return (
                                <li className={className} key={suggestion.value} onClick={() => onClick(index)}>
                                    {suggestion.label}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                return (
                    <div className={"no-suggestions"}>
                        <em>Saisie d'une référence manuelle</em>
                    </div>
                );
            }
        }
    }

    return (
        <div className={"autocomplete-container"}>
            <input
                type="text"
                className={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                onChange={onChange}
                onKeyDown={onKeyDown}
                value={userInput}
            />
            {renderSuggestionsList()}
        </div>
    );
}

export {Autocomplete}
