import React, {useEffect, useState} from 'react'
import * as Yup from "yup";
import {useFormik} from "formik";
import {TextInput} from "../../../../../components/inputs/TextInput";
import {
    ModeleCentre,
    ModeleClient,
    ModeleClientDemande,
    ModeleClientDemandeEtape, ModeleClientDemandePiece, modeleDemandePieceInitValues, ModeleImmatriculation,
    ModeleUtilisateur
} from "../../../../../components/Models";
import {Link, useNavigate} from "react-router-dom";
import {KTIcon} from "../../../../../_metronic/helpers";
import {
    createClientDemande,
    deleteClientAdresse,
    getCatdata,
    getClientAdresse, getClientDemandes, getDemandePieceSuggestion, getShopToken, postClientDemandeAssign
} from "../../../../../components/requests";
import {centres, pays, typePieces} from "../../../../../components/Data";
import {SelectInput} from "../../../../../components/inputs/SelectInput";
import {Autocomplete} from "../../../../../components/Autocomplete";
import Swal from "sweetalert2";
import moment from "moment/moment";
import {renderNiveauDelai, renderStatut} from "../../../../../components/renders";
import {formatTypePiecePourDemande} from "../../../../../components/helpers";
import {useAuth} from "../../../../modules/auth";

const clientDemandeSchema = Yup.object().shape({
    commentaire: Yup.string().notRequired(),
})

const ClientDemandeForm = (props) => {
    const {currentUser} = useAuth()
    const navigate = useNavigate();
    const [clientId, setClientId] = useState(props.clientId);
    const [demandeId, setDemandeId] = useState(props.demandeId);
    const [immatriculationValide, setImmatriculationValide] = useState(false);
    const [immatriculation, setImmatriculation] = useState<ModeleImmatriculation>({});
    const [data, setData] = useState<ModeleClientDemande>(props.data)
    const [pieces, setPieces] = useState<Array<ModeleClientDemandePiece>>([])
    const [tempsEcoule, setTempsEcoule] = useState<string | null>(null);
    const [suggestionsProduits, setSuggestionsProduits] = useState<any>({});
    const [loading, setLoading] = useState(false)
    const [loadingCatdata, setLoadingCatdata] = useState(false)

    const formik = useFormik<ModeleClientDemande>({
        initialValues: data,
        validationSchema: clientDemandeSchema,
        onSubmit: (values) => {
            setLoading(true)
            setTimeout(() => {
                const updatedData = Object.assign({...data}, values)

                props.submitForm(updatedData, pieces, response => {
                    formik.resetForm();
                    setLoading(false);
                });
            }, 200)
        },
    })

    useEffect(() => {
        if (demandeId !== undefined && demandeId !== null) {
            if (data.pieces !== undefined) {
                const tmpPieces = [...data.pieces];
                tmpPieces.map(piece => {
                    if (piece.pieceEntre !== undefined && piece.pieceEntre !== null) {
                        piece.numeroPieceEntre = piece.pieceEntre.code;
                        piece.idPieceEntre = piece.pieceEntre.id;
                    }
                })
                setPieces(tmpPieces);
            }
            if (data.donneesImmatriculation !== undefined) {
                setImmatriculation(data.donneesImmatriculation);
            }
            setImmatriculationValide(true);
        }
    }, [demandeId]);

    useEffect(() => {
        if (pieces !== undefined && pieces !== null && pieces.length) {
            pieces.map(piece => {
                //console.log("piece", piece);
                if (piece.id !== undefined && piece.id !== null && piece.typePiece !== undefined && piece.typePiece !== null) {
                    getDemandePieceSuggestion(demandeId, piece.id).then(suggestionRequest => {
                        if (suggestionRequest?.status === 200 && piece.id !== undefined) {
                            const updatedSuggestions = suggestionsProduits;
                            if (suggestionRequest?.data !== undefined && suggestionRequest?.data !== null) {
                                updatedSuggestions[piece.id] = suggestionRequest?.data;
                            } else {
                                updatedSuggestions[piece.id] = null;
                            }
                            setSuggestionsProduits({...updatedSuggestions})
                        }
                    });
                }
            })
        }
    }, [pieces]);

    useEffect(() => {
        if (demandeId !== undefined && demandeId !== null) {
            const intervalId = setInterval(() => {
                const currentDate = moment();
                const dateDemande = moment(data?.dateCreation);

                var duration = moment.duration(currentDate.diff(dateDemande));

                let totalSeconds : number = duration.asSeconds();
                let hours = Math.floor(totalSeconds / 3600);
                totalSeconds %= 3600;
                let minutes = Math.floor(totalSeconds / 60);
                let seconds = totalSeconds % 60;

                const hoursStr = String(hours).padStart(2, "0");
                const minutesStr = String(minutes).padStart(2, "0");
                const secondsStr = String(seconds.toFixed(0)).padStart(2, "0");

                setTempsEcoule(`${hoursStr}:${minutesStr}:${secondsStr}`)
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [demandeId])

    const verifyCatdata = () => {
        if (formik.values.immatriculation) {
            setLoadingCatdata(true);
            try {
                getCatdata(formik.values.immatriculation).then(immatriculationRequest => {
                    if (immatriculationRequest?.status === 200) {
                        if (immatriculationRequest?.data !== undefined) {
                            const updatedData = Object.assign({...immatriculation}, immatriculationRequest?.data)
                            setImmatriculation({...updatedData})
                            setImmatriculationValide(true);
                            formik.setFieldValue("immatriculation", immatriculationRequest?.data.sImmatriculationSiv);
                        }
                    }
                });
            } catch (ex) {
                console.error(ex)
            } finally {
                setLoadingCatdata(false);
            }
        }
    }

    const deletePieceline = (index) => {
        Swal.fire({
            title: 'Attention',
            text: 'Merci de confirmer la suppression',
            icon: 'warning',
            confirmButtonText: 'Confirmer',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            confirmButtonColor: "#1BC5BD",
            cancelButtonColor: "#F64E60",
        }).then(alertResult => {
            if (alertResult.isConfirmed) {
                const updatedPieces = pieces;
                updatedPieces.splice(index, 1);
                setPieces([...updatedPieces]);
            }
        })
    }

    const renderPiecesTable = () => {
        return (
            <>
                {renderFormRowPieces()}
            </>
        )
    }

    const updateFieldValue = (field, index, value) => {
        const updatedPieces = pieces;
        updatedPieces[index][field] = value;
        setPieces([...updatedPieces]);
    }

    const renderFormRowPieces = () => {
        //console.log("pieces", pieces);
        return pieces.map((piece, index) => {
            return (
                <tr key={index}>
                    <td>
                        <p
                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                            {piece.id !== undefined && piece.id !== null
                                ?
                                [piece.typePiece !== undefined && piece.typePiece !== null && piece.typePiece?.id !== undefined
                                    ?
                                    formatTypePiecePourDemande(piece.typePiece)
                                    :
                                    piece.referenceManuelle
                                ]
                                :
                                piece.nomTypePiece
                            }
                        </p>
                    </td>
                    <td>
                        <p
                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                            <input
                                type={"text"}
                                className={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                name={"commentaire"+index}
                                placeholder={"Commentaire"}
                                value={piece.commentaire}
                                onChange={(e) => {
                                    updateFieldValue("commentaire", index, e.target.value);
                                }}
                            />
                        </p>
                    </td>
                    <td>
                        <p
                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                value={1}
                                name={"dispoOccasion"+index}
                                checked={piece.dispoOccasion}
                                onChange={(e) => {
                                    updateFieldValue("dispoOccasion", index, e.target.checked);
                                }}
                            />
                        </p>
                    </td>
                    <td>
                        <p
                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                            <input
                                type={"number"}
                                className={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                name={"delaiOccasion"+index}
                                placeholder={"Délai occasion"}
                                value={piece.delaiOccasion}
                                onChange={(e) => {
                                    updateFieldValue("delaiOccasion", index, e.target.value);
                                }}
                            />
                        </p>
                    </td>
                    <td>
                        <p
                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                            <input
                                type={"number"}
                                className={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                name={"prixUnitaire"+index}
                                placeholder={"Prix unitaire"}
                                value={piece.prixUnitaire}
                                onChange={(e) => {
                                    updateFieldValue("prixUnitaire", index, e.target.value);
                                }}
                            />
                        </p>
                    </td>
                    <td>
                        <p
                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                            <input
                                type={"text"}
                                className={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                name={"numeroPieceEntre"+index}
                                placeholder={"NE Pièce"}
                                value={piece.numeroPieceEntre}
                                onChange={(e) => {
                                    updateFieldValue("numeroPieceEntre", index, e.target.value);
                                }}
                            />
                        </p>
                    </td>
                    <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                            {piece.id !== undefined && suggestionsProduits[piece.id] !== undefined && suggestionsProduits[piece.id] !== null &&
                                <a
                                    href={`https://shop.koena.fr/piece?sku=${suggestionsProduits[piece.id]}`}
                                    target={"_blank"}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                    <i className='bi bi-basket fs-5'></i>
                                </a>
                            }
                            {((demandeId === null) || (demandeId !== null && (!data?.clientDemandeEtape || data?.clientDemandeEtape?.id === 1))) &&
                                <p
                                    onClick={() => {
                                        deletePieceline(index)
                                    }}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                    <i className='bi bi-trash fs-5'></i>
                                </p>
                            }
                        </div>
                    </td>
                </tr>
            )
        })
    }

    const renderFormSearchPieceAutocomplete = () => {
        if (demandeId && data?.clientDemandeEtape && data?.clientDemandeEtape?.id !== 1) {
            return null;
        }
        return (
            <Autocomplete suggestions={typePieces} onSelect={(suggestion) => {
                //console.log("suggestion", suggestion);
                const updatedPieces = pieces;
                updatedPieces.push(Object.assign({...modeleDemandePieceInitValues}, {
                    idTypePiece: suggestion.value,
                    nomTypePiece: suggestion.label
                }));
                setPieces([...updatedPieces]);
            }}/>
        );
    }

    const renderActions = () => {
        if (demandeId === null) {
            return null;
        }

        const buttons: React.ReactElement[] = [];

        if (data?.clientDemandeEtape && data?.clientDemandeEtape?.id === 1) {
            buttons.push(
                <div className='btn btn-primary align-self-center mx-1' onClick={() => {
                    setLoading(true);
                    postClientDemandeAssign(clientId, demandeId, null).then(clientDemandeRequest => {
                        if (clientDemandeRequest?.status === 200) {
                            if (clientDemandeRequest?.data !== undefined) {
                                const updatedData = Object.assign({...data}, clientDemandeRequest?.data)
                                if (updatedData.centre?.id !== undefined) {
                                    updatedData.idCentre = updatedData.centre?.id
                                }
                                if (updatedData.utilisateurAssigne?.id !== undefined) {
                                    updatedData.idUtilisateurAssigne = updatedData.utilisateurAssigne?.id
                                }

                                setData({...updatedData})
                            }
                        }
                        setLoading(false);
                    }).catch(() => {
                        setLoading(false);
                    });
                }}>
                    Me l'assigner
                </div>
            );
        }

        if (data?.clientDemandeEtape && data?.clientDemandeEtape?.id === 2) {
            buttons.push(
                <div className='btn btn-primary align-self-center mx-1' onClick={() => {
                    getShopToken().then(shopTokenRequest => {
                        if (shopTokenRequest?.status === 200) {
                            if (shopTokenRequest?.data !== undefined) {
                                window.open(`https://shop.koena.fr/switchuserfromkoena?userid=${currentUser?.shopSaleAgentId}&pin=${currentUser?.shopSaleAgentPin}&token=${shopTokenRequest?.data}&clientid=${data.client?.shopCustomerId}&demandeid=${demandeId}&immatriculation=${data.immatriculation}`, "_blank", "noreferrer");
                            }
                        }
                    });
                }}>
                    Boutique
                </div>
            )
        }

        if (data?.clientDemandeEtape && data?.clientDemandeEtape?.id === 3) {
            buttons.push(
                <div className='btn btn-success align-self-center mx-1' onClick={() => {
                }}>
                    Accepter
                </div>
            )
            buttons.push(
                <div className='btn btn-danger align-self-center mx-1' onClick={() => {
                }}>
                    Décliner
                </div>
            )
        }

        /*if (data?.clientDemandeEtape && data?.clientDemandeEtape?.id !== undefined && data?.clientDemandeEtape?.id > 1) {
            buttons.push(
                <div className='btn btn-success align-self-center mx-1' onClick={() => {

                }}>
                    Accepter
                </div>
            )
        }*/

        return buttons;
    }

    return (
        <div>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
                <div className='card-body border-top p-9'>

                    {demandeId !== null
                        ?
                        <div className={"d-flex justify-content-between"}>
                            <div className='card-title mb-4'>
                                <h3 className='fw-bolder m-0'>Demande de pièces n°{demandeId}</h3>
                            </div>
                            <div>
                                {renderActions()}
                            </div>
                        </div>
                        :
                        <>
                            <div className='card-title mb-4'>
                                <h3 className='fw-bolder m-0'>Identifier le véhicule</h3>
                            </div>
                            {!immatriculationValide
                                ?
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>Veuillez saisir l'immaticulation du véhicule</div>
                                </div>
                                :
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block text-success'>Véhicule identifié</div>
                                </div>
                            }
                        </>
                    }


                    <div className={"row"}>
                        <div className='col-lg-6'>
                            {demandeId !== null
                                ?
                                <div>
                                    <div className='row mb-2'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Statut</label>
                                        <p className={"col-lg-8 col-form-label mb-0"}>{renderStatut(data?.clientDemandeEtape)}</p>
                                    </div>
                                    <div className='row mb-2'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Date</label>
                                        <p className={"col-lg-8 col-form-label mb-0"}>{moment(data?.dateCreation).format('DD/MM/YYYY \à HH:mm')}</p>
                                    </div>
                                    <div className='row mb-2'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Temps écoulé</label>
                                        <p className={"col-lg-8 col-form-label mb-0"}>
                                            {renderNiveauDelai(data?.dateCreation)}
                                            <span className={"fw-bold fs-6 ms-2"}>{tempsEcoule}</span>
                                        </p>
                                    </div>
                                    <div className='row mb-2'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Assignée à</label>
                                        <p className={"col-lg-8 col-form-label mb-0"}>
                                            {data?.utilisateurAssigne?.id !== undefined &&
                                                data?.utilisateurAssigne?.prenom + " " + data?.utilisateurAssigne?.nom
                                            }
                                        </p>
                                    </div>
                                </div>
                                :
                                <div className='d-flex flex-column'>
                                    <div className='d-lg-flex align-lg-items-center'>
                                        <div
                                            className='rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5 w-xxl-850px h-lg-60px me-lg-10 my-5'>
                                            <div className='row flex-grow-1 mb-5 mb-lg-0'>
                                                <div className='col d-flex align-items-center mb-3 mb-lg-0'>
                                                    <KTIcon iconName='magnifier' className='fs-1 text-gray-400 me-1'/>
                                                    <TextInput
                                                        fieldName={'immatriculation'}
                                                        inputPlaceholder={'Immatriculation'}
                                                        inputType={'text'}
                                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                                        formik={formik}
                                                        disabled={immatriculationValide}
                                                    />
                                                </div>
                                            </div>
                                            <div className='min-w-150px text-end'>
                                                <button type='button' className='btn btn-dark'
                                                        disabled={loadingCatdata || immatriculationValide}
                                                        onClick={() => {
                                                            verifyCatdata();
                                                        }}>
                                                    {!loadingCatdata && 'Rechercher'}
                                                    {loadingCatdata && (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                                                            Recherche...{' '}
                                                            <span
                                                                className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='col-lg-6'>
                            {immatriculationValide &&
                                <>
                                    <div className='row mb-2'>
                                        <div className='d-flex'>
                                            <label
                                                className='col col-form-label fw-bold fs-4'>{immatriculation.stecMarque} {immatriculation.stecModele} {immatriculation.sversion}</label>
                                            {demandeId === null &&
                                                <p
                                                    onClick={() => {
                                                        setImmatriculationValide(false);
                                                    }}
                                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                                >
                                                    <i className='bi bi-trash fs-5 px-3'></i>
                                                </p>
                                            }
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <div className='row mb-2'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Date 1ère
                                                    circulation</label>
                                                <p className={"col-lg-8 col-form-label mb-0"}>{immatriculation.sdatePme}</p>
                                            </div>
                                            <div className='row mb-2'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>N° série</label>
                                                <p className={"col-lg-8 col-form-label mb-0"}>{immatriculation.snoSerie}</p>
                                            </div>
                                            <div className='row mb-2'>
                                                <label
                                                    className='col-lg-4 col-form-label fw-bold fs-6'>Carosserie</label>
                                                <p className={"col-lg-8 col-form-label mb-0"}>{immatriculation.scarrosserie}</p>
                                            </div>
                                            <div className='row mb-2'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Code
                                                    moteur</label>
                                                <p className={"col-lg-8 col-form-label mb-0"}>{immatriculation.scodeMoteur}</p>
                                            </div>
                                            <div className='row mb-2'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Code
                                                    moteur tecdoc</label>
                                                <p className={"col-lg-8 col-form-label mb-0"}>{immatriculation.stecCodeMoteurTecdocEquivalent}</p>
                                            </div>
                                            <div className='row mb-2'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Code VIN</label>
                                                <p className={"col-lg-8 col-form-label mb-0"}>{immatriculation.sVin}</p>
                                            </div>
                                            <div className='row mb-2'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Type</label>
                                                <p className={"col-lg-8 col-form-label mb-0"}>{immatriculation.stypeVin}</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='row mb-2'>
                                                <label
                                                    className='col-lg-4 col-form-label fw-bold fs-6'>Cylindrée</label>
                                                <p className={"col-lg-8 col-form-label mb-0"}>{immatriculation.stecCylindree}</p>
                                            </div>
                                            <div className='row mb-2'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Energie</label>
                                                <p className={"col-lg-8 col-form-label mb-0"}>{immatriculation.stecEnergie}</p>
                                            </div>
                                            <div className='row mb-2'>
                                                <label
                                                    className='col-lg-4 col-form-label fw-bold fs-6'>Puissance
                                                    CV</label>
                                                <p className={"col-lg-8 col-form-label mb-0"}>{immatriculation.spuissanceCv}</p>
                                            </div>
                                            <div className='row mb-2'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Puissance
                                                    fiscale</label>
                                                <p className={"col-lg-8 col-form-label mb-0"}>{immatriculation.spuissanceFiscale}</p>
                                            </div>
                                            <div className='row mb-2'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Type
                                                    boîte</label>
                                                <p className={"col-lg-8 col-form-label mb-0"}>{immatriculation.stypeBoiteVitesse}</p>
                                            </div>
                                            <div className='row mb-2'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Couleur</label>
                                                <p className={"col-lg-8 col-form-label mb-0"}>{immatriculation.scouleur}</p>
                                            </div>
                                            <div className='row mb-2'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Nombre
                                                    portes</label>
                                                <p className={"col-lg-8 col-form-label mb-0"}>{immatriculation.stecNbPortes}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>

                    <div style={immatriculationValide ? {} : {display: 'none'}}>
                        <div className='card-title mb-4 mt-5'>
                            <h3 className='fw-bolder m-0'>Recherche de pièces</h3>
                        </div>

                        <div className='row mb-6'>

                            {renderFormSearchPieceAutocomplete()}

                            <div className='table-responsive'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                    <tr className='fw-bold text-muted'>
                                        <th className='min-w-150px'>Référence pièce</th>
                                        <th className='min-w-140px'>Commentaire</th>
                                        <th className='min-w-140px'>Dispo Occasion</th>
                                        <th className='min-w-140px'>Délai Occasion</th>
                                        <th className='min-w-140px'>Prix unitaire</th>
                                        <th className='min-w-140px'>NE pièce</th>
                                        <th className='min-w-100px text-end'>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {renderPiecesTable()}
                                    </tbody>
                                </table>
                            </div>


                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold required fs-6'>Centre</label>

                            <div className='col-lg-8 fv-row'>
                                <SelectInput
                                    fieldName={'idCentre'}
                                    inputClassName={'form-select form-select-solid form-select-lg fw-bold'}
                                    options={centres}
                                    formik={formik}
                                />
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Commentaire</label>

                            <div className='col-lg-8 fv-row'>
                                <TextInput
                                    fieldName={'commentaire'}
                                    inputPlaceholder={'Commentaire'}
                                    inputType={'text'}
                                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                    formik={formik}
                                />
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Communications</label>

                            <div className='col-lg-8 fv-row'>
                                <div className='d-flex align-items-center mt-3'>
                                    <label className='form-check form-check-inline form-check-solid me-5'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value={1}
                                            name={"sms"}
                                            defaultChecked={formik.values.sms}
                                            onChange={(e) => {
                                                formik.setFieldValue("sms", e.target.checked);
                                            }}
                                        />
                                        <span className='fw-bold ps-2 fs-6'>SMS</span>
                                    </label>

                                    <label className='form-check form-check-inline form-check-solid'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value={1}
                                            name={"appel"}
                                            defaultChecked={formik.values.appel}
                                            onChange={(e) => {
                                                formik.setFieldValue("appel", e.target.checked);
                                            }}
                                        />
                                        <span className='fw-bold ps-2 fs-6'>Appel</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    {demandeId !== null &&
                        <button type='button' className='btn btn-light me-3' disabled={loading} onClick={() => {
                            formik.resetForm();
                            navigate(`/vente/clients/${clientId}/afficher/demandes`);
                        }}>
                        Annuler les modifications
                        </button>
                    }
                    <button type='submit' className='btn btn-primary' disabled={loading}>
                        {!loading && 'Enregistrer'}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                  Enregistrement...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                        )}
                    </button>
                </div>
            </form>
        </div>
    )
}

export {ClientDemandeForm}
