import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {ModeleUtilisateur, modeleUtilisateurInitValues} from "../../../components/Models";
import {editUtilisateur, getClient, getUtilisateur} from "../../../components/requests";
import {LoadingData} from "../../../components/LoadingData";
import {UtilisateurForm} from "./components/UtilisateurForm";

const UtilisateurEditPage = () => {
    const navigate = useNavigate();
    let { utilisateurId } = useParams();
    const [data, setData] = useState<ModeleUtilisateur>(modeleUtilisateurInitValues);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getUtilisateurData()
    }, [utilisateurId]);

    const getUtilisateurData = () => {
        getUtilisateur(utilisateurId).then(utilisateurRequest => {
            if (utilisateurRequest?.status === 200) {
                if (utilisateurRequest?.data?.id !== undefined && utilisateurRequest?.data?.id !== null) {
                    const updatedData = Object.assign({...data}, utilisateurRequest?.data)

                    if (updatedData.role?.id !== undefined) {
                        updatedData.idRole = updatedData.role?.id
                    }

                    setData({...updatedData})
                }
                setLoading(false);
            } else {
                navigate(`/utilisateurs`);
            }
        }).catch((e) => {
            setLoading(false);
            navigate(`/utilisateurs`);
        });
    }

    const submitForm = async (fieldsToUpdate: Partial<ModeleUtilisateur>, callback): Promise<void> => {
        const updatedData = Object.assign({...data}, fieldsToUpdate)

        var requestOk = false;
        try {
            const clientRequest = await editUtilisateur(utilisateurId, updatedData);
            if (clientRequest?.status === 200) {
                requestOk = true;
            }
        } catch (ex) {
            console.error(ex)
        } finally {
            callback();
            if (requestOk) {
                setData({...updatedData})
                navigate(`/utilisateurs`);
            }
        }
    }

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Modifier l'utilisateur</h3>
                </div>
            </div>

            <div>
                {loading
                    ?
                    <LoadingData />
                    :
                    <UtilisateurForm utilisateurId={utilisateurId} data={data} submitForm={submitForm}/>
                }
            </div>
        </div>
    )
}

export {UtilisateurEditPage}
