import React from "react";

const SelectInput = (props) => {
    return (
        <>
            <select
                className={props.inputClassName}
                {...props.formik.getFieldProps(props.fieldName)}
            >
                {props.options.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                ))}
            </select>
            {props.formik.touched[props.fieldName] && props.formik.errors[props.fieldName] && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{props.formik.errors[props.fieldName]}</div>
                </div>
            )}
        </>
    )
}

export {SelectInput}
