import {Response} from "../_metronic/helpers";
import {getSearchVehiculeAndPieces} from "./requests";

export interface ModeleSelectInput {
    value?: string
    label?: string
}

export interface ModeleRecherchePiece {
    reference?: string
}

export const modeleRecherchePieceValues: ModeleRecherchePiece = {
    reference: "",
}

export interface ModeleRechercheVehicule {
    immatriculation?: string
    cnit?: string
}

export const modeleRechercheVehiculeValues: ModeleRechercheVehicule = {
    immatriculation: "",
    cnit: "",
}

export interface ModelePieceItem {
    codePiece?: string
    typePiece?: string
    referenceConstructeur?: string
    prixConstructeur?: string
    prixVenteMin?: string
    prixVenteMax?: string
    stock?: number
}

export interface ModeleVehiculeAndPieces {
    vehicule?: ModeleVehicule|undefined
    donneesImmatriculation?: ModeleImmatriculation|undefined
    donneesPieces?: Array<ModelePieceItem>|undefined
}

export type VehiculeAndPiecesQueryResponse = Response<ModeleVehiculeAndPieces>

export interface ModelePays {
    id?: number
    code2?: string
    code3?: string
    nom?: string
}

export interface ModeleCentre {
    id?: number
    code?: string
    nom?: string
    adresse?: string
    complementAdresse?: string
    codePostal?: string
    ville?: string
    telephone?: string
    email?: string
    siret?: string
    pays?: ModelePays
}

export interface ModeleCategorieTypePiece {
    id?: number
    code?: string
    nom?: string
}

export interface ModeleSousCategorieTypePiece {
    id?: number
    code?: string
    nom?: string
    categorie?: ModeleCategorieTypePiece
}

export interface ModeleTypePiece {
    id?: number
    code?: string
    nom?: string
    sousCategorie?: ModeleSousCategorieTypePiece
}

export interface ModeleUtilisateurRole {
    id?: number
    code?: string
    libelle?: string
}

export interface ModeleUtilisateur {
    id?: number
    nom?: string
    prenom?: string
    email?: string
    pseudo?: string
    roles?: Array<string>
    idRole?: number
    role?: ModeleUtilisateurRole
    password?: string,
    listeFonctionnalites?: Array<string>
    listeModules?: Array<string>
}

export const modeleUtilisateurInitValues : ModeleUtilisateur = {
    nom: "",
    prenom: "",
    email: "",
    pseudo: "",
    roles: [],
    password: "",
    listeFonctionnalites: [],
    listeModules: [],
    idRole: 1,
}

export type UtilisateursQueryResponse = Response<Array<ModeleUtilisateur>>
export type UtilisateurQueryResponse = Response<ModeleUtilisateur>

export interface ModeleVehiculeGroupe {
    id?: number
    code?: string
}

export interface ModeleVehiculeType {
    id?: number
    code?: string
    idCentreDefaut?: number
    centreDefaut?: ModeleCentre
}

export interface ModeleVehiculeMarque {
    id?: number
    nom?: string
    popularite?: number
}

export interface ModeleVehiculeModele {
    id?: number
    idMarque?: number
    Marque?: ModeleVehiculeMarque
    nom?: string
    phase?: string
    cylindre?: string
    puissance?: string
    codeMoteur?: string
    debutPhase?: string
    finPhase?: string
    idType?: number
    Type?: ModeleVehiculeType
}

export interface ModeleVehicule {
    id?: number
    idModele?: number
    modele?: ModeleVehiculeModele
    idGroupe?: number
    groupe?: ModeleVehiculeGroupe
    cnit?: string
}

export interface ModelePieceEntre {
    id?: number
    id_piece?: number
    id_vehicule_entre?: number
    id_etat?: number
    code?: string
    prix?: number
    prix_transport?: number
    taux_tva?: number
    id_reserve_demande?: number
    date_reserve_demande?: string
    id_site?: number
    poids?: number
    commentaire?: string
    non_mecanisable?: boolean
    stockage_allee?: string
    stockage_bac?: string
    stockage_date?: string
}

export interface ModeleImmatriculation {
    id?: number
    sCodeErreur?: string
    sMessageErreur?: string
    sNomPartenaire?: string
    sNomPartenaireBase?: string
    sNomLogiciel?: string
    sLicence?: string
    sImmatriculationSiv?: string
    sVin?: string
    sCnit?: string
    stypeVin?: string
    smarque?: string
    smarqueCarrosserie?: string
    smodele?: string
    smodeleEtude?: string
    smodelePrf?: string
    sversion?: string
    senergie?: string
    sgenre?: string
    sgenreCg?: string
    scarrosserie?: string
    scarrosserieCg?: string
    snbVolumes?: string
    snbPortes?: string
    sdatePme?: string
    spuissanceKw?: string
    stypeBoiteVitesse?: string
    stypeVarianteVersion?: string
    spuissanceFiscale?: string
    sco2?: string
    sconsommationMixte?: string
    sconsommationUrbaine?: string
    sconsommationExtraUrbaine?: string
    sdepollution?: string
    scouleur?: string
    sdateCg?: string
    spoidsVide?: string
    spoidsTotalRoulant?: string
    spoidsTotalRoulantPrf?: string
    sempattement?: string
    shauteur?: string
    slargeur?: string
    slongueur?: string
    snbPlacesAssises?: string
    snbCylindres?: string
    snbSoupapes?: string
    smodeInjection?: string
    snbVitesses?: string
    sturbo?: string
    stransmission?: string
    scylindree?: string
    spuissanceCv?: string
    snoSerie?: string
    snoSiren?: string
    scodeMoteur?: string
    sprix?: string
    stecCatid?: string
    stecMarque?: string
    stecCodeMoteur?: string
    stecCodeMoteurTecdocEquivalent?: string
    stecEnergie?: string
    stecCylindree?: string
    stecPuissanceKw?: string
    stecAnneeDeb?: string
    stecAnneeFin?: string
    stecPhase?: string
    stecModele?: string
    stecCarrosserie1?: string
    stecCarrosserie2?: string
    stecNbPortes?: string
    stecTransmission?: string
    stecBoiteVitesse1?: string
    stecBoiteVitesse2?: string
    stecVersion1?: string
    stecVersion2?: string
    stecVersion3?: string
}

export type ImmatriculationQueryResponse = Response<ModeleImmatriculation>

/*
CLIENTS
 */

export interface ModeleClient {
    id?: number
    professionnel?: boolean
    nomSociete?: string
    nom?: string
    prenom?: string
    adresse?: string
    complementAdresse?: string
    codePostal?: string
    ville?: string
    idPays?: number
    pays?: ModelePays
    siren?: string
    telephone?: string
    mobile?: string
    email?: string
    siteWeb?: string
    shopCustomerId?: number
}

export const modeleClientInitValues: ModeleClient = {
    professionnel: true,
    idPays: 75,
}

export type ClientsQueryResponse = Response<Array<ModeleClient>>
export type ClientQueryResponse = Response<ModeleClient>

export interface ModeleClientOtherData {
    ca?: number
    demandesOk?: number
    demandesPasOk?: number
}

export const modeleClientOtherDataInitValues: ModeleClientOtherData = {
    ca: 0,
    demandesOk: 0,
    demandesPasOk: 0,
}

export type ClientOtherDataQueryResponse = Response<ModeleClientOtherData>

export interface ModeleClientAdresse {
    id?: number
    libelle?: string
    adresse?: string
    complementAdresse?: string
    codePostal?: string
    ville?: string
    idPays?: number
    pays?: ModelePays
    telephone?: string
    siret?: string
    idClient?: number
    client?: ModeleClient
}

export const modeleClientAdresseInitValues: ModeleClientAdresse = {
    idPays: 75,
}

export type ClientAdressesQueryResponse = Response<Array<ModeleClientAdresse>>
export type ClientAdresseQueryResponse = Response<ModeleClientAdresse>

export interface ModeleClientContact {
    id?: number
    nom?: string
    prenom?: string
    telephone?: string
    mobile?: string
    email?: string
    posteOccupe?: string
    principal?: boolean
    client?: ModeleClient
}

export const modeleClientContactInitValues: ModeleClientContact = {
}

export type ClientContactsQueryResponse = Response<Array<ModeleClientContact>>
export type ClientContactQueryResponse = Response<ModeleClientContact>


/*
DEMANDES
 */

export interface ModeleClientDemandeEtape {
    id?: number
    code?: string
    libelle?: string
}

export interface ModeleClientDemande {
    id?: number
    dateCreation?: string
    sms?: boolean
    appel?: boolean
    immatriculation?: string
    commentaire?: string
    idCentre?: number
    centre?: ModeleCentre
    idClient?: number
    client?: ModeleClient
    utilisateurSaisie?: ModeleUtilisateur
    idUtilisateurAssigne?: number
    utilisateurAssigne?: ModeleUtilisateur
    clientDemandeEtape?: ModeleClientDemandeEtape
    pieces?: Array<ModeleClientDemandePiece>
    donneesImmatriculation?: ModeleImmatriculation
}

export const modeleClientDemandeInitValues: ModeleClientDemande = {
    pieces: []
}

export type ClientDemandesQueryResponse = Response<Array<ModeleClientDemande>>
export type ClientDemandeQueryResponse = Response<ModeleClientDemande>

export interface ModeleClientDemandePiece {
    id?: number
    referenceManuelle?: string
    commentaire?: string
    neuf?: boolean
    occasion?: boolean
    dispoNeuf?: boolean
    dispoOccasion?: boolean
    delaiNeuf?: number
    delaiOccasion?: number
    prixUnitaire?: number
    idDemande?: number
    demande?: ModeleClientDemande
    idTypePiece?: number
    nomTypePiece?: string
    typePiece?: ModeleTypePiece
    numeroPieceEntre?: string
    idPieceEntre?: number
    pieceEntre?: ModelePieceEntre
}

export const modeleDemandePieceInitValues: ModeleClientDemandePiece = {
    referenceManuelle: "",
    commentaire: "",
    neuf: false,
    occasion: false,
    dispoNeuf: false,
    dispoOccasion: false,
    delaiNeuf: 0,
    delaiOccasion: 0,
    prixUnitaire: 0,
}

export type ClientDemandePiecesQueryResponse = Response<Array<ModeleClientDemandePiece>>
export type ClientDemandePieceQueryResponse = Response<ModeleClientDemandePiece>

export type AnyQueryResponse = Response<any>
