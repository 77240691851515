import React, {useState} from 'react'
import * as Yup from "yup";
import {useFormik} from "formik";
import {TextInput} from "../../../../../components/inputs/TextInput";
import {ModeleClientAdresse} from "../../../../../components/Models";
import {SelectInput} from "../../../../../components/inputs/SelectInput";
import {pays} from "../../../../../components/Data";
import {useNavigate} from "react-router-dom";

const clientAdresseSchema = Yup.object().shape({
    libelle: Yup.string().notRequired(),
    adresse: Yup.string().required("L'adresse est requise"),
    complementAdresse: Yup.string().notRequired(),
    codePostal: Yup.string().required("Le code postal est requis"),
    ville: Yup.string().required("La ville est requise"),
    siret: Yup.string().notRequired(),
    telephone: Yup.string().notRequired(),
})

const ClientAdresseForm = (props) => {
    const navigate = useNavigate();
    const [clientId, setClientId] = useState(props.clientId);
    const [adresseId, setAdresseId] = useState(props.adresseId);
    const [data, setData] = useState<ModeleClientAdresse>(props.data)
    const [loading, setLoading] = useState(false)

    const formik = useFormik<ModeleClientAdresse>({
        initialValues: data,
        validationSchema: clientAdresseSchema,
        onSubmit: (values) => {
            setLoading(true)
            setTimeout(() => {
                const updatedData = Object.assign({...data}, values)

                props.submitForm(updatedData, response => {
                    formik.resetForm();
                    setLoading(false);
                });
            }, 200)
        },
    })

    return (
        <div>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
                <div className='card-body border-top p-9'>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Libellé de l'adresse</label>

                        <div className='col-lg-8 fv-row'>
                            <TextInput
                                fieldName={'libelle'}
                                inputPlaceholder={'Libellé'}
                                inputType={'text'}
                                inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                formik={formik}
                            />
                        </div>
                    </div>

                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Siret</label>

                        <div className='col-lg-8 fv-row'>
                            <TextInput
                                fieldName={'siret'}
                                inputPlaceholder={'SIRET'}
                                inputType={'text'}
                                inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                formik={formik}
                            />
                        </div>
                    </div>

                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Adresse</label>

                        <div className='col-lg-8'>
                            <div className='row mb-4'>
                                <div className='col-lg-6 fv-row'>
                                    <TextInput
                                        fieldName={'adresse'}
                                        inputPlaceholder={'Adresse'}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>

                                <div className='col-lg-6 fv-row'>
                                    <TextInput
                                        fieldName={'complementAdresse'}
                                        inputPlaceholder={"Complément d'adresse"}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-lg-6 fv-row'>
                                    <TextInput
                                        fieldName={'codePostal'}
                                        inputPlaceholder={'Code postal'}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>

                                <div className='col-lg-6 fv-row'>
                                    <TextInput
                                        fieldName={'ville'}
                                        inputPlaceholder={'Ville'}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 fv-row'>
                                    <SelectInput
                                        fieldName={'idPays'}
                                        inputClassName={'form-select form-select-solid form-select-lg fw-bold'}
                                        options={pays}
                                        formik={formik}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Téléphone</label>

                        <div className='col-lg-8'>
                            <TextInput
                                fieldName={'telephone'}
                                inputPlaceholder={'Fixe'}
                                inputType={'text'}
                                inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                formik={formik}
                            />
                        </div>
                    </div>
                </div>

                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    {adresseId !== null &&
                        <button type='button' className='btn btn-light me-3' disabled={loading} onClick={() => {
                            formik.resetForm();
                            navigate(`/vente/clients/${clientId}/afficher/adresses`);
                        }}>
                            Annuler les modifications
                        </button>
                    }
                    <button type='submit' className='btn btn-primary' disabled={loading}>
                        {!loading && 'Enregistrer'}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                  Enregistrement...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                        )}
                    </button>
                </div>
            </form>
        </div>
    )
}

export {ClientAdresseForm}
