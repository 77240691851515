import React, {useEffect, useState} from 'react'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'
import {useLocation} from "react-router";
import {Link} from "react-router-dom";
import clsx from "clsx";
import {checkIsActive, KTIcon} from "../../../helpers";
import {getCatdata, getShopToken} from "../../../../components/requests";
import {useAuth} from "../../../../app/modules/auth";

export function MenuInner() {
  const intl = useIntl()
  const location = useLocation()
  const {currentUser} = useAuth()

  const [basePath, setBasePath] = useState('');

  useEffect(() => {
    updateBasePath();
  }, []);

  useEffect(() => {
    updateBasePath();
  }, [location]);

  const updateBasePath = () => {
    if (location.pathname !== undefined && location.pathname !== null) {
      let pathname = location?.pathname;
      if (pathname.substring(0, 1) === "/") {
        pathname = pathname?.substring(1);
      }
      const firstPathElem = pathname?.split("/")[0];
      setBasePath(firstPathElem);
    } else {
      setBasePath("");
    }
  }

  if (basePath === "vente") {
    return (
        <>
          <MenuItem title='Clients' to='/vente/clients'/>
          <div className='menu-item me-lg-1'>
            <div
                className={clsx('menu-link py-3')}
                onClick={() => {
                  getShopToken().then(shopTokenRequest => {
                    if (shopTokenRequest?.status === 200) {
                      if (shopTokenRequest?.data !== undefined) {
                        window.open(`https://shop.koena.fr/loginfromkoena?userid=${currentUser?.shopSaleAgentId}&pin=${currentUser?.shopSaleAgentPin}&token=${shopTokenRequest?.data}`, "_blank", "noreferrer");
                      }
                    }
                  });
                }}
            >
              <span className='menu-title'>Shop</span>
            </div>
          </div>
        </>
    )
  }

  if (basePath === "vehicules") {
    return (
        <>
          <MenuItem title='Recherche' to='/vehicules/recherche'/>
          <MenuItem title='Pièces' to='/vehicules/pieces'/>
        </>
    )
  }

  return (<></>);

  /*return (
      <>
        <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard'/>
        <MenuItem title='Layout Builder' to='/builder'/>
        <MenuInnerWithSub
            title='Crafted'
            to='/crafted'
            menuPlacement='bottom-start'
            menuTrigger='click'
        >
          {/!* PAGES *!/}
          <MenuInnerWithSub
              title='Pages'
              to='/crafted/pages'
              fontIcon='bi-archive'
              hasArrow={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuInnerWithSub
                title='Profile'
                to='/crafted/pages/profile'
                hasArrow={true}
                hasBullet={true}
                menuPlacement='right-start'
                menuTrigger={`{default:'click', lg: 'hover'}`}
            >
              <MenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
              <MenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
              <MenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
              <MenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
              <MenuItem
                  to='/crafted/pages/profile/connections'
                  title='Connections'
                  hasBullet={true}
              />
            </MenuInnerWithSub>
            <MenuInnerWithSub
                title='Wizards'
                to='/crafted/pages/wizards'
                hasArrow={true}
                hasBullet={true}
                menuPlacement='right-start'
                menuTrigger={`{default:'click', lg: 'hover'}`}
            >
              <MenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
              <MenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
            </MenuInnerWithSub>
          </MenuInnerWithSub>

          {/!* ACCOUNT *!/}
          <MenuInnerWithSub
              title='Accounts'
              to='/crafted/accounts'
              fontIcon='bi-person'
              hasArrow={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
            <MenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
          </MenuInnerWithSub>

          {/!* ERRORS *!/}
          <MenuInnerWithSub
              title='Errors'
              to='/error'
              fontIcon='bi-sticky'
              hasArrow={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
            <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
          </MenuInnerWithSub>

          {/!* Widgets *!/}
          <MenuInnerWithSub
              title='Widgets'
              to='/crafted/widgets'
              fontIcon='bi-layers'
              hasArrow={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
            <MenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
            <MenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
            <MenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
            <MenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
            <MenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
          </MenuInnerWithSub>
        </MenuInnerWithSub>

        <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click'>
          {/!* PAGES *!/}
          <MenuInnerWithSub
              title='Chat'
              to='/apps/chat'
              icon='message-text-2'
              hasArrow={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
            <MenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
            <MenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
          </MenuInnerWithSub>
          <MenuItem icon='shield-tick' to='/apps/user-management/users' title='User management' />
        </MenuInnerWithSub>

        <MenuInnerWithSub
            isMega={true}
            title='Mega menu'
            to='/mega-menu'
            menuPlacement='bottom-start'
            menuTrigger='click'
        >
          <MegaMenu />
        </MenuInnerWithSub>
      </>
  )*/
}
