import React, {useEffect, useState} from 'react'
import {KTIcon} from "../../../../../_metronic/helpers";
import {Link} from "react-router-dom";
import {ModeleClient} from "../../../../../components/Models";
import {getClients} from "../../../../../components/requests";
import {LoadingData} from "../../../../../components/LoadingData";

const ClientsListPage = () => {
    const [data, setData] = useState<Array<ModeleClient>>([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getClientsData()
    }, []);

    const getClientsData = () => {
        getClients().then(clientsRequest => {
            if (clientsRequest?.status === 200) {
                if (clientsRequest?.data !== undefined) {
                    setData(clientsRequest?.data)
                    setLoading(false);
                }
            }
        });
    }

    return (
        <>
            {/* begin::Row  */}
            <div className='row g-5 g-xl-8'>
                {/* begin::Col  */}
                <div className='col-xxl-12'>
                    <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
                        {/* begin::Header */}
                        <div className='card-header border-0 pt-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bold fs-3 mb-1'>Clients</span>
                            </h3>
                            <div
                                className='card-toolbar'
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                data-bs-trigger='hover'
                                title='Click to add a user'
                            >
                                <Link
                                    to={`/vente/clients/ajouter`}
                                    className='btn btn-sm btn-light-primary'
                                >
                                    <KTIcon iconName='plus' className='fs-3'/>
                                    Créer un client
                                </Link>
                            </div>
                        </div>
                        {/* end::Header */}
                        {loading
                            ?
                            <LoadingData />
                            :
                            <div className='card-body py-3'>
                                {/* begin::Table container */}
                                <div className='table-responsive'>
                                    {/* begin::Table */}
                                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                        {/* begin::Table head */}
                                        <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='min-w-150px'>Client</th>
                                            <th className='min-w-140px'>Société</th>
                                            <th className='min-w-140px'>Localisation</th>
                                            <th className='min-w-140px'>Type</th>
                                            <th className='min-w-100px text-end'>Actions</th>
                                        </tr>
                                        </thead>
                                        {/* end::Table head */}
                                        {/* begin::Table body */}
                                        <tbody>
                                        {data.map((client) => {
                                            return (
                                                <tr key={client.id}>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='d-flex justify-content-start flex-column'>
                                                                <Link
                                                                    to={`/vente/clients/${client.id}/afficher`}
                                                                   className='text-dark fw-bold text-hover-primary fs-6 mb-0'>
                                                                    {client.nom} {client.prenom}
                                                                </Link>
                                                                <span
                                                                    className='text-muted fw-semibold text-muted d-block fs-7'>
                                                                Groupe (GPART01, GPART02)
                                                            </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {client.nomSociete}
                                                        </p>
                                                        <span
                                                            className='text-muted fw-semibold text-muted d-block fs-7'>
                                                        Type société (Garage, carosserie...)
                                                    </span>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {client.ville}
                                                        </p>
                                                        <span
                                                            className='text-muted fw-semibold text-muted d-block fs-7'>
                                                        {client.codePostal} {client.pays?.nom}
                                                    </span>
                                                    </td>
                                                    <td>
                                                        {client.professionnel
                                                            ?
                                                            <span
                                                                className={`badge badge-light-warning fw-bolder me-auto px-4 py-3`}>
                                                                Professionnel
                                                            </span>
                                                            :
                                                            <span
                                                                className={`badge badge-light-danger fw-bolder me-auto px-4 py-3`}>
                                                                Particulier
                                                            </span>
                                                        }
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-end flex-shrink-0'>
                                                            <Link
                                                                to={`/vente/clients/${client.id}/afficher/demandes/ajouter`}
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            >
                                                                <i className='bi bi-bag-plus-fill fs-5'></i>
                                                            </Link>
                                                            <Link
                                                                to={`/vente/clients/${client.id}/afficher`}
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            >
                                                                <i className='bi bi-person-vcard fs-5'></i>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                        {/* end::Table body */}
                                    </table>
                                    {/* end::Table */}
                                </div>
                                {/* end::Table container */}
                            </div>
                        }
                    </div>
                </div>
                {/* end::Col  */}
            </div>
            {/* end::Row  */}
        </>
    )
}

export {ClientsListPage}
