import axios, {AxiosResponse} from 'axios'
import {
    AnyQueryResponse,
    ClientAdresseQueryResponse,
    ClientAdressesQueryResponse,
    ClientContactQueryResponse,
    ClientContactsQueryResponse,
    ClientDemandeQueryResponse,
    ClientDemandesQueryResponse,
    ClientOtherDataQueryResponse,
    ClientQueryResponse,
    ClientsQueryResponse,
    ImmatriculationQueryResponse,
    ModeleClient,
    ModeleClientAdresse,
    ModeleClientContact,
    ModeleClientDemande,
    ModeleClientDemandePiece,
    ModeleUtilisateur,
    UtilisateurQueryResponse,
    UtilisateursQueryResponse,
    VehiculeAndPiecesQueryResponse
} from "./Models";
import {User} from "../app/modules/apps/user-management/users-list/core/_models";
import {Response} from "../_metronic/helpers";

const API_URL = process.env.REACT_APP_API_URL
const CLIENT_BASE_URL = `${API_URL}/client`
const UTILISATEUR_BASE_URL = `${API_URL}/utilisateur`
const PIECE_BASE_URL = `${API_URL}/piece`

export const getCatdata = async (immatriculation: string | undefined): Promise<ImmatriculationQueryResponse | undefined> => {
    return await axios
        .get(`${API_URL}/catdata?immat=${immatriculation}`)
}

export const getShopToken = async (): Promise<Response<string>> => {
    return await axios
        .get(`${API_URL}/shop_token`)
}

export const getUtilisateurs = async (): Promise<UtilisateursQueryResponse> => {
    return await axios
        .get(`${UTILISATEUR_BASE_URL}`)
}

export const getUtilisateur = async (utilisateurId: string | undefined): Promise<UtilisateurQueryResponse> => {
    return await axios
        .get(`${UTILISATEUR_BASE_URL}/${utilisateurId}`)
}

export const createUtilisateur = async (utilisateur: ModeleUtilisateur): Promise<UtilisateurQueryResponse | undefined> => {
    return await axios
        .post(UTILISATEUR_BASE_URL, utilisateur)
}

export const editUtilisateur = async (utilisateurId: string | undefined, utilisateur: ModeleUtilisateur): Promise<UtilisateurQueryResponse | undefined> => {
    return await axios
        .post(`${UTILISATEUR_BASE_URL}/${utilisateurId}/edit`, utilisateur)
}

export const getClients = async (): Promise<ClientsQueryResponse> => {
    return await axios
        .get(`${CLIENT_BASE_URL}`)
}

export const getClient = async (clientId: string | undefined): Promise<ClientQueryResponse> => {
    return await axios
        .get(`${CLIENT_BASE_URL}/${clientId}`)
}

export const getClientOtherData = async (clientId: string | undefined): Promise<ClientOtherDataQueryResponse> => {
    return await axios
        .get(`${CLIENT_BASE_URL}/${clientId}/others`)
}

export const createClient = async (client: ModeleClient): Promise<ClientQueryResponse | undefined> => {
    return await axios
        .post(CLIENT_BASE_URL, client)
}

export const editClient = async (clientId: string | undefined, client: ModeleClient): Promise<ClientQueryResponse | undefined> => {
    return await axios
        .post(`${CLIENT_BASE_URL}/${clientId}/edit`, client)
}

export const getClientContacts = async (clientId: string | undefined): Promise<ClientContactsQueryResponse> => {
    return await axios
        .get(`${CLIENT_BASE_URL}/${clientId}/contacts`)
}

export const getClientContact = async (clientId: string | undefined, contactId: string | undefined): Promise<ClientContactQueryResponse> => {
    return await axios
        .get(`${CLIENT_BASE_URL}/${clientId}/contact/${contactId}`)
}

export const createClientContact = async (clientId: string | undefined, contact: ModeleClientContact): Promise<ClientContactQueryResponse | undefined> => {
    return await axios
        .post(`${CLIENT_BASE_URL}/${clientId}/contact`, contact)
}

export const editClientContact = async (clientId: string | undefined, contactId: string | undefined, contact: ModeleClientContact): Promise<ClientContactQueryResponse | undefined> => {
    return await axios
        .post(`${CLIENT_BASE_URL}/${clientId}/contact/${contactId}`, contact)
}

export const deleteClientContact = async (clientId: string | undefined, contactId: string | undefined): Promise<ClientContactQueryResponse | undefined> => {
    return await axios
        .delete(`${CLIENT_BASE_URL}/${clientId}/contact/${contactId}`)
}

export const getClientAdresses = async (clientId: string | undefined): Promise<ClientAdressesQueryResponse> => {
    return await axios
        .get(`${CLIENT_BASE_URL}/${clientId}/adresses`)
}

export const getClientAdresse = async (clientId: string | undefined, adresseId: string | undefined): Promise<ClientAdresseQueryResponse> => {
    return await axios
        .get(`${CLIENT_BASE_URL}/${clientId}/adresse/${adresseId}`)
}

export const createClientAdresse = async (clientId: string | undefined, adresse: ModeleClientAdresse): Promise<ClientAdresseQueryResponse | undefined> => {
    return await axios
        .post(`${CLIENT_BASE_URL}/${clientId}/adresse`, adresse)
}

export const editClientAdresse = async (clientId: string | undefined, adresseId: string | undefined, adresse: ModeleClientAdresse): Promise<ClientAdresseQueryResponse | undefined> => {
    return await axios
        .post(`${CLIENT_BASE_URL}/${clientId}/adresse/${adresseId}`, adresse)
}

export const deleteClientAdresse = async (clientId: string | undefined, adresseId: string | undefined): Promise<ClientAdresseQueryResponse | undefined> => {
    return await axios
        .delete(`${CLIENT_BASE_URL}/${clientId}/adresse/${adresseId}`)
}

export const getClientDemandes = async (clientId: string | undefined): Promise<ClientDemandesQueryResponse> => {
    return await axios
        .get(`${CLIENT_BASE_URL}/${clientId}/demandes`)
}

export const getClientDemande = async (clientId: string | undefined, demandeId: string | undefined): Promise<ClientDemandeQueryResponse> => {
    return await axios
        .get(`${CLIENT_BASE_URL}/${clientId}/demande/${demandeId}`)
}

export const postClientDemandeAssign = async (clientId: string | undefined, demandeId: string | undefined, userId: number | null): Promise<ClientDemandeQueryResponse> => {
    if (userId) {
        return await axios
            .post(`${CLIENT_BASE_URL}/${clientId}/demande/${demandeId}/assign`, {user: userId})
    } else {
        return await axios
            .post(`${CLIENT_BASE_URL}/${clientId}/demande/${demandeId}/assign`, {})
    }
}

export const createClientDemande = async (clientId: string | undefined, demande: ModeleClientDemande, pieces: Array<ModeleClientDemandePiece | undefined>): Promise<ClientDemandeQueryResponse | undefined> => {
    return await axios
        .post(`${CLIENT_BASE_URL}/${clientId}/demande`, {
            ...demande,
            pieces: JSON.stringify(pieces)
        })
}

export const getDemandePieceSuggestion = async (demandeId: string | undefined, demandePieceId: number | undefined): Promise<Response<string|null>> => {
    return await axios
        .get(`${PIECE_BASE_URL}/demande/${demandeId}/suggestion/${demandePieceId}`)
}

export const getSearchVehiculeAndPieces = async (immatriculation: string | undefined, cnit: string | undefined): Promise<VehiculeAndPiecesQueryResponse | undefined> => {
    if (immatriculation) {
        return await axios
            .get(`${API_URL}/searchvandp?immatriculation=${immatriculation}`)
    } else if (cnit) {
        return await axios
            .get(`${API_URL}/searchvandp?cnit=${cnit}`)
    }
}

export const getSearchPiece = async (reference: string | undefined): Promise<AnyQueryResponse | undefined> => {
    return await axios
        .get(`${API_URL}/searchp?reference=${reference}`)
}
