import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Navigate, Route, Routes} from 'react-router-dom'
import {ClientsWrapper} from "./clients/ClientsWrapper";

const ShopWrapper = () => {
  const intl = useIntl()
  return (
      <>
          <PageTitle breadcrumbs={[]}>Vente</PageTitle>
          <Routes>

              <Route
                  path='clients/*'
                  element={
                      <>
                          <ClientsWrapper />
                      </>
                  }
              />
              <Route index element={<Navigate to='/vente/clients' />} />
          </Routes>
      </>
  )
}

export {ShopWrapper}
