import React from 'react'
import {useNavigate} from "react-router-dom";
import {ModeleUtilisateur, modeleUtilisateurInitValues} from "../../../components/Models";
import {UtilisateurForm} from "./components/UtilisateurForm";
import {createUtilisateur} from "../../../components/requests";

const UtilisateurAddPage = () => {
    const navigate = useNavigate();
    const submitForm = async (fieldsToUpdate: Partial<ModeleUtilisateur>, callback): Promise<void> => {
        const updatedData = Object.assign({...modeleUtilisateurInitValues}, fieldsToUpdate)

        var requestOk = false;
        try {
            const clientRequest = await createUtilisateur(updatedData);
            if (clientRequest?.status === 200) {
                if (clientRequest?.data?.id !== undefined && clientRequest?.data?.id !== null) {
                    requestOk = true;
                    navigate(`/utilisateurs`);
                }
            }
        } catch (ex) {
            console.error(ex)
        } finally {
            callback();
        }

    }

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Créer un utilisateur</h3>
                </div>
            </div>

            <div>
                <UtilisateurForm utilisateurId={null} data={modeleUtilisateurInitValues} submitForm={submitForm} />
            </div>
        </div>
    )
}

export {UtilisateurAddPage}
