/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from "../../../../app/modules/auth";
import {shouldDisplayModule} from "../../../../components/helpers";

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser} = useAuth()

    //console.log("currentUser", currentUser);

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title="Accueil"
        fontIcon='bi-house'
        bsTitle="Accueil"
        className='py-3'
      />
        {shouldDisplayModule(currentUser, "VENTE") &&
            <AsideMenuItem
                to='/vente'
                title='Vente'
                bsTitle='Vente'
                fontIcon='bi-basket'
                className='py-3'
            />
        }
        {shouldDisplayModule(currentUser, "VEHIC") &&
            <AsideMenuItem
                to='/vehicules'
                title='Véhicules'
                bsTitle='Véhicules'
                fontIcon='bi-car-front'
                className='py-3'
            />
        }
        {shouldDisplayModule(currentUser, "UTILI") &&
            <AsideMenuItem
                to='/utilisateurs'
                title='Utilisateurs'
                bsTitle='Utilisateurs'
                fontIcon='bi-person-lines-fill'
                className='py-3'
            />
        }
    </>
  )
}
