import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    ModeleClientAdresse, modeleClientAdresseInitValues,
    ModeleClientDemande,
    modeleClientDemandeInitValues,
    ModeleClientDemandePiece
} from "../../../../../../components/Models";
import {
    createClientDemande,
    getClientAdresse,
    getClientDemande,
    getShopToken
} from "../../../../../../components/requests";
import {ClientDemandeForm} from "../../components/ClientDemandeForm";
import {LoadingData} from "../../../../../../components/LoadingData";
import {ClientAdresseForm} from "../../components/ClientAdresseForm";
import {useAuth} from "../../../../../modules/auth";

const ClientDemandeEdit = () => {
    const navigate = useNavigate();
    let { clientId } = useParams();
    let { demandeId } = useParams();
    const [data, setData] = useState<ModeleClientDemande>(modeleClientDemandeInitValues);
    const [refreshKey, setRefreshKey] = useState(1);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getClientDemandeData()
    }, [clientId, demandeId]);

    useEffect(() => {
        setRefreshKey(refreshKey+1);
    }, [data]);

    const getClientDemandeData = () => {
        getClientDemande(clientId, demandeId).then(clientDemandeRequest => {
            if (clientDemandeRequest?.status === 200) {
                if (clientDemandeRequest?.data?.id !== undefined && clientDemandeRequest?.data?.id !== null) {
                    const updatedData = Object.assign({...data}, clientDemandeRequest?.data)
                    if (updatedData.centre?.id !== undefined) {
                        updatedData.idCentre = updatedData.centre?.id
                    }
                    if (updatedData.utilisateurAssigne?.id !== undefined) {
                        updatedData.idUtilisateurAssigne = updatedData.utilisateurAssigne?.id
                    }

                    setData({...updatedData})
                }
                setLoading(false);
            } else {
                navigate(`/vente/clients/${clientId}/afficher/demandes`);
            }
        });
    }

    const submitForm = async (fields: Partial<ModeleClientDemande>, pieces: Partial<Array<ModeleClientDemandePiece>>, callback): Promise<void> => {
        const updatedData = Object.assign({...modeleClientDemandeInitValues}, fields)

        var requestOk = false;
        try {
            const clientDemandeRequest = await createClientDemande(clientId, updatedData, pieces);
            if (clientDemandeRequest?.status === 200) {
                if (clientDemandeRequest?.data?.id !== undefined && clientDemandeRequest?.data?.id !== null) {
                    requestOk = true;
                }
            }
        } catch (ex) {
            console.error(ex)
        } finally {
            callback();
            if (requestOk) {
                navigate(`/vente/clients/${clientId}/afficher/demandes`);
            }
        }

    }

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Traiter une demande</h3>
                    <p
                        onClick={() => {
                            getClientDemandeData();
                        }}
                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                    >
                        <i className='bi bi-arrow-clockwise fs-5 px-3'></i>
                    </p>
                </div>
            </div>

            <div>
                {loading
                    ?
                    <LoadingData/>
                    :
                    <ClientDemandeForm clientId={clientId} demandeId={demandeId} data={data} submitForm={submitForm} key={refreshKey}/>
                }
            </div>
        </div>
    )
}

export {ClientDemandeEdit}
