import React from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {ModeleClientContact, modeleClientContactInitValues} from "../../../../../../components/Models";
import {createClientContact} from "../../../../../../components/requests";
import {ClientContactForm} from "../../components/ClientContactForm";

const ClientContactAdd = () => {
    const navigate = useNavigate();
    let { clientId } = useParams();
    const submitForm = async (fieldsToUpdate: Partial<ModeleClientContact>, callback): Promise<void> => {
        const updatedData = Object.assign({...modeleClientContactInitValues}, fieldsToUpdate)

        var requestOk = false;
        try {
            const clientContactRequest = await createClientContact(clientId, updatedData);
            if (clientContactRequest?.status === 200) {
                if (clientContactRequest?.data?.id !== undefined && clientContactRequest?.data?.id !== null) {
                    requestOk = true;
                }
            }
        } catch (ex) {
            console.error(ex)
        } finally {
            callback();
            if (requestOk) {
                navigate(`/vente/clients/${clientId}/afficher/contacts`);
            }
        }

    }

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Créer un contact</h3>
                </div>
            </div>

            <div>
                <ClientContactForm clientId={clientId} contactId={null} data={modeleClientContactInitValues} submitForm={submitForm} />
            </div>
        </div>
    )
}

export {ClientContactAdd}
