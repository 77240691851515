import React from "react";

const TextInput = (props) => {
    return (
        <>
            <input
                type={props.inputType}
                className={props.inputClassName}
                placeholder={props.inputPlaceholder}
                disabled={props.disabled}
                {...props.formik.getFieldProps(props.fieldName)}
            />
            {props.formik.touched[props.fieldName] && props.formik.errors[props.fieldName] && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{props.formik.errors[props.fieldName]}</div>
                </div>
            )}
        </>
    )
}

export {TextInput}
