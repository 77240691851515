import React from "react";
import {KTIcon} from "../_metronic/helpers";

const LoadingData = (props) => {
    return (
        <div
            className='d-flex flex-column flex-center h-100 p-6'
        >
            <KTIcon iconName='loading' className='fs-3x text-primary mb-2'/>
            <span className='fs-7 fw-bold text-gray-800 mb-0'>Chargement des données</span>
        </div>
    )
}

export {LoadingData}
