import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from "react-router-dom";
import {ModeleClientContact} from "../../../../../../components/Models";
import {LoadingData} from "../../../../../../components/LoadingData";
import {deleteClientContact, getClientContacts} from "../../../../../../components/requests";
import Swal from "sweetalert2";

const ClientContacts = () => {
    const navigate = useNavigate();
    let { clientId } = useParams();
    const [data, setData] = useState<Array<ModeleClientContact>>([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getClientData()
    }, [clientId]);

    const deleteContact = (contactId) => {
        Swal.fire({
            title: 'Attention',
            text: 'Merci de confirmer la suppression',
            icon: 'warning',
            confirmButtonText: 'Confirmer',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            confirmButtonColor: "#1BC5BD",
            cancelButtonColor: "#F64E60",
        }).then(alertResult => {
            if (alertResult.isConfirmed) {
                deleteClientContact(clientId, contactId).then(response => {
                    if (response?.status === 200) {
                        getClientData()
                    }
                })
            }
        })
    }

    const getClientData = () => {
        getClientContacts(clientId).then(clientContactsRequest => {
            if (clientContactsRequest?.status === 200) {
                if (clientContactsRequest?.data !== undefined) {
                    setData(clientContactsRequest?.data)
                    setLoading(false);
                }
            } else {
                navigate(`/vente/clients/liste`);
            }
        });
    }

    return (
        <>
            {loading
                ?
                <LoadingData/>
                :
                <>
                    <div className='card mb-5 mb-xl-10'>
                        <div
                            className='card-header border-0'
                        >
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Contacts client</h3>
                            </div>

                            <Link to={`/vente/clients/${clientId}/afficher/contacts/ajouter`} className='btn btn-primary align-self-center'>
                                Ajouter
                            </Link>
                        </div>

                        <div>
                            <div className='card-body p-9'>


                                {/* begin::Table container */}
                                <div className='table-responsive'>
                                    {/* begin::Table */}
                                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                        {/* begin::Table head */}
                                        <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='min-w-150px'>Nom</th>
                                            <th className='min-w-140px'>Téléphone</th>
                                            <th className='min-w-140px'>Email</th>
                                            <th className='min-w-140px'>Poste</th>
                                            <th className='min-w-100px text-end'>Actions</th>
                                        </tr>
                                        </thead>
                                        {/* end::Table head */}
                                        {/* begin::Table body */}
                                        <tbody>
                                        {data.map((clientContact) => {
                                            return (
                                                <tr key={clientContact.id}>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {clientContact.nom} {clientContact.prenom}
                                                            {clientContact.principal &&
                                                                <span
                                                                    className={`badge badge-light-warning fw-bolder me-auto px-4 py-3 ms-2`}>
                                                                Contact principal
                                                            </span>
                                                            }
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {clientContact.telephone}<br/>
                                                            {clientContact.mobile}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {clientContact.email}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {clientContact.posteOccupe}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-end flex-shrink-0'>
                                                            <p
                                                                onClick={() => {
                                                                    deleteContact(clientContact.id)
                                                                }}
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            >
                                                                <i className='bi bi-trash fs-5'></i>
                                                            </p>
                                                            <Link
                                                                to={`/vente/clients/${clientId}/afficher/contacts/${clientContact.id}/modifier`}
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            >
                                                                <i className='bi bi-pencil fs-5'></i>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                        {/* end::Table body */}
                                    </table>
                                    {/* end::Table */}
                                </div>
                                {/* end::Table container */}
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export {ClientContacts}
