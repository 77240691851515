import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from "react-router-dom";
import {ModeleClientDemande, ModeleClientDemandeEtape} from "../../../../../../components/Models";
import {LoadingData} from "../../../../../../components/LoadingData";
import {getClientDemandes} from "../../../../../../components/requests";
import moment from "moment";
import {renderNiveauDelai, renderStatut} from "../../../../../../components/renders";

const ClientDemandes = () => {
    const navigate = useNavigate();
    let { clientId } = useParams();
    const [data, setData] = useState<Array<ModeleClientDemande>>([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getClientData()
    }, [clientId]);

    const getClientData = () => {
        getClientDemandes(clientId).then(clientDemandesRequest => {
            if (clientDemandesRequest?.status === 200) {
                if (clientDemandesRequest?.data !== undefined) {
                    setData(clientDemandesRequest?.data.reverse())
                    setLoading(false);
                }
            } else {
                navigate(`/vente/clients/liste`);
            }
        });
    }

    return (
        <>
            {loading
                ?
                <LoadingData/>
                :
                <>
                    <div className='card mb-5 mb-xl-10'>
                        <div
                            className='card-header border-0'
                        >
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Demandes client</h3>
                            </div>

                            <Link to={`/vente/clients/${clientId}/afficher/demandes/ajouter`} className='btn btn-primary align-self-center'>
                                Ajouter
                            </Link>
                        </div>

                        <div>
                            <div className='card-body p-9'>


                                {/* begin::Table container */}
                                <div className='table-responsive'>
                                    {/* begin::Table */}
                                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                        {/* begin::Table head */}
                                        <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='min-w-150px'>Numéro</th>
                                            <th className='min-w-140px'>Assignée</th>
                                            <th className='min-w-140px'>Date</th>
                                            <th className='min-w-140px'>Délais</th>
                                            <th className='min-w-140px'>Statut</th>
                                            <th className='min-w-140px'>SMS</th>
                                            <th className='min-w-140px'>Appel</th>
                                            <th className='min-w-140px'>Client</th>
                                            <th className='min-w-140px'>Immatriculation</th>
                                            <th className='min-w-140px'>Centre</th>
                                            <th className='min-w-100px text-end'>Actions</th>
                                        </tr>
                                        </thead>
                                        {/* end::Table head */}
                                        {/* begin::Table body */}
                                        <tbody>
                                        {data.map((clientDemande) => {
                                            return (
                                                <tr key={clientDemande.id}>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='d-flex justify-content-start flex-column'>
                                                                <p
                                                                    className='text-dark fw-bold text-hover-primary fs-6 mb-0'>
                                                                    {clientDemande.id}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {clientDemande.utilisateurAssigne?.nom} {clientDemande.utilisateurAssigne?.prenom}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {moment(clientDemande.dateCreation).format('DD/MM/YYYY')}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {renderNiveauDelai(clientDemande.dateCreation)}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {renderStatut(clientDemande.clientDemandeEtape)}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {clientDemande.sms
                                                                ?
                                                                <i className='bi bi-check fs-1 text-success'></i>
                                                                :
                                                                <i className='bi bi-x fs-1 text-danger'></i>
                                                            }
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {clientDemande.appel
                                                                ?
                                                                <i className='bi bi-check fs-1 text-success'></i>
                                                                :
                                                                <i className='bi bi-x fs-1 text-danger'></i>
                                                            }
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to={`/vente/clients/${clientDemande.client?.id}/afficher`}
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {clientDemande.client?.professionnel
                                                                ?
                                                                clientDemande.client?.nomSociete
                                                                :
                                                                clientDemande.client?.nom + ' ' + clientDemande.client?.prenom
                                                            }
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {clientDemande.immatriculation}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {clientDemande.centre?.nom}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-end flex-shrink-0'>
                                                            <Link
                                                                to={`/vente/clients/${clientId}/afficher/demandes/${clientDemande.id}/modifier`}
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            >
                                                                <i className='bi bi-pencil fs-5'></i>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                        {/* end::Table body */}
                                    </table>
                                    {/* end::Table */}
                                </div>
                                {/* end::Table container */}
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export {ClientDemandes}
