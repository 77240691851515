import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import {ClientForm} from "../shop/clients/components/ClientForm";
import {
    ModeleClient,
    modeleClientInitValues,
    ModeleImmatriculation,
    ModelePieceItem,
    ModeleRechercheVehicule, modeleRechercheVehiculeValues, ModeleVehicule
} from "../../../components/Models";
import * as Yup from "yup";
import {createClient, getClients, getSearchVehiculeAndPieces} from "../../../components/requests";
import {useFormik} from "formik";
import {TextInput} from "../../../components/inputs/TextInput";

const rechercheVehiculeSchema = Yup.object().shape({
    immatriculation: Yup.string().notRequired(),
    cnit: Yup.string().notRequired(),
})

const RecherchePage = () => {
    const [data, setData] = useState<ModeleRechercheVehicule>(modeleRechercheVehiculeValues);
    const [dataImmatriculation, setDataImmatriculation] = useState<ModeleImmatriculation | undefined>({});
    const [dataVehicule, setDataVehicule] = useState<ModeleVehicule | undefined>({});
    const [dataPieces, setDataPieces] = useState<Array<ModelePieceItem> | undefined>([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (data.immatriculation || data.cnit) {
            setLoading(true)
            submitForm();
        }
    }, [data]);

    const formik = useFormik<ModeleRechercheVehicule>({
        initialValues: data,
        validationSchema: rechercheVehiculeSchema,
        onSubmit: (values) => {
            setTimeout(() => {
                const updatedData = Object.assign({...data}, values)

                setData(updatedData);
            }, 200)
        },
    })

    const submitForm = async (): Promise<void> => {
        try {
            getSearchVehiculeAndPieces(data.immatriculation, data.cnit).then(vehiculeAndPiecesRequest => {
                if (vehiculeAndPiecesRequest?.status === 200) {
                    if (vehiculeAndPiecesRequest?.data !== undefined) {
                        setDataImmatriculation(vehiculeAndPiecesRequest?.data?.donneesImmatriculation);
                        setDataVehicule(vehiculeAndPiecesRequest?.data?.vehicule);
                        setDataPieces(vehiculeAndPiecesRequest?.data?.donneesPieces);

                        setLoading(false);
                    }
                }
            });
        } catch (ex) {
            console.error(ex)
            setLoading(false);
        }

    }

    return (
        <>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Recherche</h3>
                    </div>
                </div>

                <div>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card-body border-top p-9'>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Immatriculation</label>

                                <div className='col-lg-8 fv-row'>
                                    <TextInput
                                        fieldName={'immatriculation'}
                                        inputPlaceholder={'Immatriculation'}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>CNIT</label>

                                <div className='col-lg-8 fv-row'>
                                    <TextInput
                                        fieldName={'cnit'}
                                        inputPlaceholder={'CNIT'}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                {!loading && 'Rechercher'}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                  Recherche...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {dataImmatriculation?.id !== undefined &&
                <div className='row g-5 g-xl-8'>
                    {/* begin::Col  */}
                    <div className='col-xxl-12'>
                        <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
                            {/* begin::Header */}
                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bold fs-3 mb-1'>Véhicule</span>
                                </h3>
                            </div>
                            {/* end::Header */}
                            <div className='card-body py-3'>
                                {/* begin::Table container */}
                                <div className='table-responsive'>
                                    {/* begin::Table */}
                                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                        {/* begin::Table head */}
                                        <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='min-w-150px'>Attribut</th>
                                            <th className='min-w-140px'>Valeur</th>
                                        </tr>
                                        </thead>
                                        {/* end::Table head */}
                                        {/* begin::Table body */}
                                        <tbody>
                                        {Object.keys(dataImmatriculation).map((keyName, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <p
                                                        className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                        {keyName}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p
                                                        className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                        {dataImmatriculation[keyName]}
                                                    </p>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                        {/* end::Table body */}
                                    </table>
                                    {/* end::Table */}
                                </div>
                                {/* end::Table container */}
                            </div>
                        </div>
                    </div>
                    {/* end::Col  */}
                </div>
            }

            {dataPieces !== undefined && dataPieces?.length > 0 &&
                <div className='row g-5 g-xl-8'>
                    {/* begin::Col  */}
                    <div className='col-xxl-12'>
                        <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
                            {/* begin::Header */}
                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bold fs-3 mb-1'>Pièces</span>
                                </h3>
                            </div>
                            {/* end::Header */}
                            <div className='card-body py-3'>
                                {/* begin::Table container */}
                                <div className='table-responsive'>
                                    {/* begin::Table */}
                                    <table
                                        className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                        {/* begin::Table head */}
                                        <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='min-w-150px'>Code pièce</th>
                                            <th className='min-w-140px'>Type pièce</th>
                                            <th className='min-w-140px'>Ref. constructeur</th>
                                            <th className='min-w-140px'>Prix constructeur</th>
                                            <th className='min-w-140px'>Prix vente min</th>
                                            <th className='min-w-140px'>Prix vente max</th>
                                            <th className='min-w-140px'>En Stock</th>
                                            <th className='min-w-100px text-end'>Actions</th>
                                        </tr>
                                        </thead>
                                        {/* end::Table head */}
                                        {/* begin::Table body */}
                                        <tbody>
                                        {dataPieces.map((piece, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {piece.codePiece}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {piece.typePiece}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {piece.referenceConstructeur}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {piece.prixConstructeur}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {piece.prixVenteMin}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {piece.prixVenteMax}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {piece.stock}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-end flex-shrink-0'>
                                                            <Link
                                                                to={`/vehicules/pieces/${piece.referenceConstructeur}`}
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            >
                                                                <i className='bi bi-currency-euro fs-5'></i>
                                                            </Link>
                                                            <a
                                                                href={`https://www.surplusautos.fr/search-modele.html?tws_piece_reference_oe=${piece.referenceConstructeur}`}
                                                                target={"_blank"}
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            >
                                                                <i className='bi bi-basket fs-5'></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                        {/* end::Table body */}
                                    </table>
                                    {/* end::Table */}
                                </div>
                                {/* end::Table container */}
                            </div>
                        </div>
                    </div>
                    {/* end::Col  */}
                </div>
            }

        </>
    )
}

export {RecherchePage}
