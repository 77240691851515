import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Navigate, Route, Routes} from 'react-router-dom'
import {UtilisateursListPage} from "./UtilisateursListPage";
import {UtilisateurAddPage} from "./UtilisateurAddPage";
import {UtilisateurEditPage} from "./UtilisateurEditPage";

const UtilisateursWrapper = () => {
  const intl = useIntl()
  return (
      <>
          <PageTitle breadcrumbs={[]}>Utilisateurs</PageTitle>
          <Routes>

              <Route
                  path='/liste'
                  element={
                      <>
                          <UtilisateursListPage />
                      </>
                  }
              />
              <Route
                  path='/ajouter'
                  element={
                      <>
                          <UtilisateurAddPage />
                      </>
                  }
              />
              <Route
                  path=':utilisateurId/modifier'
                  element={
                      <>
                          <UtilisateurEditPage />
                      </>
                  }
              />
              <Route index element={<Navigate to='/utilisateurs/liste' />} />
          </Routes>
      </>
  )
}

export {UtilisateursWrapper}
