import React, {useEffect, useState} from 'react'
import {KTIcon} from "../../../_metronic/helpers";
import {Link} from "react-router-dom";
import {ModeleUtilisateur} from "../../../components/Models";
import {getClients, getUtilisateurs} from "../../../components/requests";
import {LoadingData} from "../../../components/LoadingData";

const UtilisateursListPage = () => {
    const [data, setData] = useState<Array<ModeleUtilisateur>>([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getUtilisateursData()
    }, []);

    const getUtilisateursData = () => {
        getUtilisateurs().then(utilisateursRequest => {
            if (utilisateursRequest?.status === 200) {
                if (utilisateursRequest?.data !== undefined) {
                    setData(utilisateursRequest?.data)
                    setLoading(false);
                }
            }
        }).catch((e) => {
            setLoading(false);
        });
    }

    return (
        <>
            {/* begin::Row  */}
            <div className='row g-5 g-xl-8'>
                {/* begin::Col  */}
                <div className='col-xxl-12'>
                    <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
                        {/* begin::Header */}
                        <div className='card-header border-0 pt-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bold fs-3 mb-1'>Utilisateurs</span>
                            </h3>
                            <div
                                className='card-toolbar'
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                data-bs-trigger='hover'
                                title='Click to add a user'
                            >
                                <Link
                                    to={`/utilisateurs/ajouter`}
                                    className='btn btn-sm btn-light-primary'
                                >
                                    <KTIcon iconName='plus' className='fs-3'/>
                                    Créer un utilisateur
                                </Link>
                            </div>
                        </div>
                        {/* end::Header */}
                        {loading
                            ?
                            <LoadingData />
                            :
                            <div className='card-body py-3'>
                                {/* begin::Table container */}
                                <div className='table-responsive'>
                                    {/* begin::Table */}
                                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                        {/* begin::Table head */}
                                        <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='min-w-150px'>Nom/Prénom</th>
                                            <th className='min-w-140px'>Nom d'utilisateur</th>
                                            <th className='min-w-100px text-end'>Actions</th>
                                        </tr>
                                        </thead>
                                        {/* end::Table head */}
                                        {/* begin::Table body */}
                                        <tbody>
                                        {data.map((utilisateur) => {
                                            return (
                                                <tr key={utilisateur.id}>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='d-flex justify-content-start flex-column'>
                                                                <Link
                                                                    to={`/utilisateurs/${utilisateur.id}/afficher`}
                                                                   className='text-dark fw-bold text-hover-primary fs-6 mb-0'>
                                                                    {utilisateur.nom} {utilisateur.prenom}
                                                                </Link>
                                                                <span
                                                                    className='text-muted fw-semibold text-muted d-block fs-7'>
                                                                {utilisateur.role?.libelle}
                                                            </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {utilisateur.pseudo}
                                                        </p>
                                                        <span
                                                            className='text-muted fw-semibold text-muted d-block fs-7'>
                                                                {utilisateur.email}
                                                            </span>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-end flex-shrink-0'>
                                                            <Link
                                                                to={`/utilisateurs/${utilisateur.id}/modifier`}
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            >
                                                                <i className='bi bi-person-vcard fs-5'></i>
                                                            </Link>
                                                            <p
                                                                onClick={() => {alert("Impossible de supprimer cet utilisateur")}}
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            >
                                                                <i className='bi bi-trash fs-5'></i>
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                        {/* end::Table body */}
                                    </table>
                                    {/* end::Table */}
                                </div>
                                {/* end::Table container */}
                            </div>
                        }
                    </div>
                </div>
                {/* end::Col  */}
            </div>
            {/* end::Row  */}
        </>
    )
}

export {UtilisateursListPage}
