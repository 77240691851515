import moment from "moment/moment";
import React from "react";
import {ModeleClientDemandeEtape} from "./Models";

export const renderNiveauDelai = (fromDate) => {
    const currentDate = moment();
    const dateDemande = moment(fromDate);

    var duration = moment.duration(currentDate.diff(dateDemande));
    var hours = duration.asHours();

    if (hours < 6) {
        return (<i className='bi bi-hourglass-top fs-5 text-success'></i>);
    } else if (hours < 12) {
        return (<i className='bi bi-hourglass-split fs-5 text-warning'></i>);
    } else {
        return (<i className='bi bi-hourglass-bottom fs-5 text-danger'></i>);
    }
}

export const renderStatut = (etape: ModeleClientDemandeEtape|undefined) => {
    let textClass = '';
    if (etape?.code) {
        if (["NOUVE"].includes(etape?.code)) {
            textClass = 'light';
        } else if (["ASSIG"].includes(etape?.code)) {
            textClass = 'light-info';
        } else if (["PROPO"].includes(etape?.code)) {
            textClass = 'info';
        } else if (["REFUS"].includes(etape?.code)) {
            textClass = 'light-danger';
        } else if (["CLOTN"].includes(etape?.code)) {
            textClass = 'danger';
        } else if (["ACCEP"].includes(etape?.code)) {
            textClass = 'light-success';
        } else if (["CLOTP"].includes(etape?.code)) {
            textClass = 'success';
        }
    }

    return (<span className={`badge badge-${textClass}`}>{etape?.libelle}</span>);
}
