import React, {useEffect, useState} from 'react'
import * as Yup from "yup";
import {useFormik} from "formik";
import {TextInput} from "../../../../../components/inputs/TextInput";
import {ModeleClient} from "../../../../../components/Models";
import {SelectInput} from "../../../../../components/inputs/SelectInput";
import {pays} from "../../../../../components/Data";
import {useNavigate} from "react-router-dom";

const clientSchema = Yup.object().shape({
    professionnel: Yup.string().default("1"),
    nomSociete: Yup.string().when(["professionnel"], {
        is: (professionnel) => (professionnel === true) ? true : Boolean(parseInt(professionnel.toString())),
        then: () => Yup.string().required('Le nom de la société est requis'),
        otherwise: () => Yup.string().notRequired()
    }),
    nom: Yup.string().when(["professionnel"], {
        is: (professionnel) => (professionnel === false) ? true : !Boolean(parseInt(professionnel.toString())),
        then: () => Yup.string().required('Le nom est requis'),
        otherwise: () => Yup.string().notRequired()
    }),
    prenom: Yup.string().when(["professionnel"], {
        is: (professionnel) => (professionnel === false) ? true : !Boolean(parseInt(professionnel.toString())),
        then: () => Yup.string().required('Le prénom est requis'),
        otherwise: () => Yup.string().notRequired()
    }),
    adresse: Yup.string().required("L'adresse est requise"),
    complementAdresse: Yup.string().notRequired(),
    codePostal: Yup.string().required("Le code postal est requis"),
    ville: Yup.string().required("La ville est requise"),
    siren: Yup.string().notRequired(),
    telephone: Yup.string().when(["mobile"], {
        is: (mobile) => !mobile || mobile.length < 1,
        then: () => Yup.string().required("Numéro de fixe ou mobile requis"),
        otherwise: () => Yup.string().notRequired()
    }),
    mobile: Yup.string().notRequired(),
    email: Yup.string().required("L'adresse mail est requise").email("L'adresse mail n'est pas valide"),
    siteWeb: Yup.string().notRequired(),
})

const ClientForm = (props) => {
    const navigate = useNavigate();
    const [clientId, setClientId] = useState(props.clientId);
    const [data, setData] = useState<ModeleClient>(props.data)
    const [loading, setLoading] = useState(false)

    const formik = useFormik<ModeleClient>({
        initialValues: data,
        validationSchema: clientSchema,
        onSubmit: (values) => {
            setLoading(true)
            setTimeout(() => {
                const updatedData = Object.assign({...data}, values)

                props.submitForm(updatedData, response => {
                    formik.resetForm();
                    setLoading(false);
                });
            }, 200)
        },
    })

    const isProfessionel = (value) => {
        if (value === false || value === true) {
            return value;
        }
        return Boolean(parseInt(value.toString()))
    }

    return (
        <div>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
                <div className='card-body border-top p-9'>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Type de client</label>

                        <div className='col-lg-8 fv-row'>
                            <div className='d-flex align-items-center mt-3'>
                                <label className='form-check form-check-inline form-check-solid me-5'>
                                    <input
                                        className='form-check-input'
                                        type='radio'
                                        value={0}
                                        name={"professionnel"}
                                        onChange={formik.handleChange}
                                        defaultChecked={!formik.values.professionnel}
                                    />
                                    <span className='fw-bold ps-2 fs-6'>Particulier</span>
                                </label>

                                <label className='form-check form-check-inline form-check-solid'>
                                    <input
                                        className='form-check-input'
                                        type='radio'
                                        value={1}
                                        name={"professionnel"}
                                        onChange={formik.handleChange}
                                        defaultChecked={formik.values.professionnel}
                                    />
                                    <span className='fw-bold ps-2 fs-6'>Société</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-6'
                         style={(!isProfessionel(formik.values.professionnel)) ? {display: "none"} : {}}>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Nom de la société</label>

                        <div className='col-lg-8 fv-row'>
                            <TextInput
                                fieldName={'nomSociete'}
                                inputPlaceholder={'Société'}
                                inputType={'text'}
                                inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                formik={formik}
                            />
                        </div>
                    </div>

                    <div className='row mb-6'
                         style={(!isProfessionel(formik.values.professionnel)) ? {display: "none"} : {}}>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Siren</label>

                        <div className='col-lg-8 fv-row'>
                            <TextInput
                                fieldName={'siren'}
                                inputPlaceholder={'SIREN'}
                                inputType={'text'}
                                inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                formik={formik}
                            />
                        </div>
                    </div>

                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Nom complet</label>

                        <div className='col-lg-8'>
                            <div className='row'>
                                <div className='col-lg-6 fv-row'>
                                    <TextInput
                                        fieldName={'nom'}
                                        inputPlaceholder={'Nom'}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>

                                <div className='col-lg-6 fv-row'>
                                    <TextInput
                                        fieldName={'prenom'}
                                        inputPlaceholder={'Prenom'}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Adresse principale</label>

                        <div className='col-lg-8'>
                            <div className='row mb-4'>
                                <div className='col-lg-6 fv-row'>
                                    <TextInput
                                        fieldName={'adresse'}
                                        inputPlaceholder={'Adresse'}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>

                                <div className='col-lg-6 fv-row'>
                                    <TextInput
                                        fieldName={'complementAdresse'}
                                        inputPlaceholder={"Complément d'adresse"}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-lg-6 fv-row'>
                                    <TextInput
                                        fieldName={'codePostal'}
                                        inputPlaceholder={'Code postal'}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>

                                <div className='col-lg-6 fv-row'>
                                    <TextInput
                                        fieldName={'ville'}
                                        inputPlaceholder={'Ville'}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 fv-row'>
                                    <SelectInput
                                        fieldName={'idPays'}
                                        inputClassName={'form-select form-select-solid form-select-lg fw-bold'}
                                        options={pays}
                                        formik={formik}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Téléphone</label>

                        <div className='col-lg-8'>
                            <div className='row'>
                                <div className='col-lg-6 fv-row'>
                                    <TextInput
                                        fieldName={'telephone'}
                                        inputPlaceholder={'Fixe'}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>

                                <div className='col-lg-6 fv-row'>
                                    <TextInput
                                        fieldName={'mobile'}
                                        inputPlaceholder={'Mobile'}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

                        <div className='col-lg-8 fv-row'>
                            <TextInput
                                fieldName={'email'}
                                inputPlaceholder={'Email'}
                                inputType={'text'}
                                inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                formik={formik}
                            />
                        </div>
                    </div>

                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Site web</label>

                        <div className='col-lg-8 fv-row'>
                            <TextInput
                                fieldName={'siteWeb'}
                                inputPlaceholder={'Site web'}
                                inputType={'text'}
                                inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                formik={formik}
                            />
                        </div>
                    </div>

                </div>

                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    {clientId !== null &&
                        <button type='button' className='btn btn-light me-3' disabled={loading} onClick={() => {
                            formik.resetForm();
                            navigate(`/vente/clients/${clientId}/afficher`);
                        }}>
                            Annuler les modifications
                        </button>
                    }
                    <button type='submit' className='btn btn-primary' disabled={loading}>
                        {!loading && 'Enregistrer'}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                  Enregistrement...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                        )}
                    </button>
                </div>
            </form>
        </div>
    )
}

export {ClientForm}
