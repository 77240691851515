import {ModeleTypePiece} from "./Models";
import moment from "moment";
import {UserModel} from "../app/modules/auth";

export const toGmapQuery = (terms: string) => {
    return terms.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ' ');
}

export const formatTypePiecePourDemande = (typePiece: ModeleTypePiece) : string => {
    var nompiece = "[";

    if (typePiece.sousCategorie?.categorie?.code !== undefined && typePiece.sousCategorie?.categorie?.code !== null) {
        nompiece += typePiece.sousCategorie?.categorie?.code;
    }
    if (typePiece.sousCategorie?.code !== undefined && typePiece.sousCategorie?.code !== null) {
        nompiece += typePiece.sousCategorie?.code;
    }
    if (typePiece.code !== undefined && typePiece.code !== null) {
        nompiece += typePiece.code;
    }

    nompiece += "] - " + typePiece.nom;

    return nompiece
}

export const formatDate = (dateTimeStr: string) : string => {
    return moment(dateTimeStr).format("DD/MM/YYYY")
}

export const shouldDisplayModule = (user: UserModel|undefined, module:string): boolean => {
    if (user !== undefined && user.listeModules !== undefined) {
        return user?.listeModules?.includes(module);
    }

    return false;
}
