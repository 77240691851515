import React, {useState} from 'react'
import * as Yup from "yup";
import {useFormik} from "formik";
import {TextInput} from "../../../../../components/inputs/TextInput";
import {ModeleClientContact} from "../../../../../components/Models";
import {useNavigate} from "react-router-dom";

const clientContactSchema = Yup.object().shape({
    nom: Yup.string().required("Le nom est requis"),
    prenom: Yup.string().required("Le prénom est requis"),
    telephone: Yup.string().when(["mobile"], {
        is: (mobile) => !mobile || mobile.length < 1,
        then: () => Yup.string().required("Numéro de fixe ou mobile requis"),
        otherwise: () => Yup.string().notRequired()
    }),
    mobile: Yup.string().notRequired(),
    email: Yup.string().email("L'adresse mail n'est pas valide"),
    posteOccupe: Yup.string().notRequired(),
})

const ClientContactForm = (props) => {
    const navigate = useNavigate();
    const [clientId, setClientId] = useState(props.clientId);
    const [contactId, setContactId] = useState(props.contactId);
    const [data, setData] = useState<ModeleClientContact>(props.data)
    const [loading, setLoading] = useState(false)

    const formik = useFormik<ModeleClientContact>({
        initialValues: data,
        validationSchema: clientContactSchema,
        onSubmit: (values) => {
            setLoading(true)
            setTimeout(() => {
                const updatedData = Object.assign({...data}, values)

                props.submitForm(updatedData, response => {
                    formik.resetForm();
                    setLoading(false);
                });
            }, 200)
        },
    })

    return (
        <div>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
                <div className='card-body border-top p-9'>

                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Nom complet</label>

                        <div className='col-lg-8'>
                            <div className='row'>
                                <div className='col-lg-6 fv-row'>
                                    <TextInput
                                        fieldName={'nom'}
                                        inputPlaceholder={'Nom'}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>

                                <div className='col-lg-6 fv-row'>
                                    <TextInput
                                        fieldName={'prenom'}
                                        inputPlaceholder={'Prenom'}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Téléphone</label>

                        <div className='col-lg-8'>
                            <div className='row'>
                                <div className='col-lg-6 fv-row'>
                                    <TextInput
                                        fieldName={'telephone'}
                                        inputPlaceholder={'Fixe'}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>

                                <div className='col-lg-6 fv-row'>
                                    <TextInput
                                        fieldName={'mobile'}
                                        inputPlaceholder={'Mobile'}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Email</label>

                        <div className='col-lg-8 fv-row'>
                            <TextInput
                                fieldName={'email'}
                                inputPlaceholder={'Email'}
                                inputType={'text'}
                                inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                formik={formik}
                            />
                        </div>
                    </div>

                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Poste occupé</label>

                        <div className='col-lg-8 fv-row'>
                            <TextInput
                                fieldName={'posteOccupe'}
                                inputPlaceholder={'Poste occupé'}
                                inputType={'text'}
                                inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                formik={formik}
                            />
                        </div>
                    </div>

                    <div className='row mb-6'>
                        <div className='col-lg-12'>
                            <label className='form-check form-check-inline form-check-solid me-5'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value={1}
                                    name={"principal"}
                                    defaultChecked={formik.values.principal}
                                    onChange={(e) => {
                                        formik.setFieldValue("principal", e.target.checked);
                                    }}
                                />
                                <span className='fw-bold ps-2 fs-6'>Contact principal</span>
                            </label>
                        </div>
                    </div>
                </div>

                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    {contactId !== null &&
                        <button type='button' className='btn btn-light me-3' disabled={loading} onClick={() => {
                            formik.resetForm();
                            navigate(`/vente/clients/${clientId}/afficher/contacts`);
                        }}>
                            Annuler les modifications
                        </button>
                    }
                    <button type='submit' className='btn btn-primary' disabled={loading}>
                        {!loading && 'Enregistrer'}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                  Enregistrement...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                        )}
                    </button>
                </div>
            </form>
        </div>
    )
}

export {ClientContactForm}
